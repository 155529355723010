import React from 'react'
import SEO from '../components/seo'
import * as commonPageStyles from '../styles/common-page.module.css'

const NaukaSpiewuPage = () => (
  <>
    <SEO title="Nauka śpiewu Jaworzno - Music Spot" 
    description="Zapraszamy na naukę śpiewu w Jaworznie, zajęcia prowadzone są indywidualnie przez doświadczonych nauczycieli wokalu. Zapoznaj się z naszą ofertą!"/>
    <section className="sub-page-header">
      <div className="wrapper">
        <h1>Nauka Śpiewu Jaworzno</h1>
      </div>
    </section>

    <div className={`${commonPageStyles.sectionsContainer} wrapper`}>
      <section>
        <p>Oferta Music Spot skierowana jest do osób:</p>
        <p>
          <li>
            {' '}
            początkujących, bez wcześniejszego doświadczenia w nauce śpiewu.
            Podczas zajęć z emisji głosu, pod okiem wykwalifikowanej i
            doświadczonej kadry pracować będziemy nad wdrożeniem zdrowych
            nawyków oraz szybką i skuteczną korekcją nabytych przyzwyczajeń,
            często ograniczających nasze możliwości.
          </li>
          <li>
            {' '}
            w wieku szkolnym, chcących spróbować swoich sił w zakresie nauki
            śpiewu i rozwijać siępod okiem profesjonalistów
          </li>
          <li>
            {' '}
            dorosłych. Zależy nam na podkreśleniu, iżlekcje śpiewania można
            podjąć niezależnie od wieku. Wymagana jest jedynie chęć rozpoczęcia
            nauki śpiewu oraz słuch muzyczny, który zostanie zweryfikowany
            podczas pierwszego spotkania.
          </li>
          <li>
            {' '}
            chcących kontynuować porzuconą wcześniej pasję. Wielu z naszych
            dotychczasowych uczniów to osoby, które z różnych przyczyn porzuciły
            bądź przerwały naukę w szkole muzycznej. Podczas zajęć wokalnych
            szybko odświeżamy zdobytą wcześniej wiedzę i wdrażamy ją do programu
            lekcji śpiewu.
          </li>
          <li>
            {' '}
            pragnących uczyć się śpiewu i wspólnego języka, który umożliwi
            profesjonalnąwspółpracę z innymi muzykami.
          </li>
          <li>
            {' '}
            przygotowujących się do egzaminów wstępnych na uczelnie artystyczne.
            Dzięki wiedzy, wykształceniu i doświadczeniu lekcje śpiewu
            kompleksowo pomagają w doborze repertuaru i pracy nad utworami w
            zależności od specjalności na jaką wybiera siękandydat. Możemy
            poszczycić się przygotowaniem obecnych adeptów akademii muzycznych i
            szkół aktorskich.
          </li>
        </p>
        <p style={{ marginTop: '50px', marginBottom: '50px' }}>
          <strong>
            Zajęcia prowadzone są w nowej siedzibie, mają charakter
            indywidualny. Istnieje możliwość prowadzenia lekcji online oraz
            dojazdu do ucznia.
          </strong>
        </p>
      </section>
      <section>
        <h3>Przebieg zajęć wokalnych</h3>
        <p>
          Bogate doświadczenie kadry umożliwia dostosowanie programu nauki
          śpiewania do aspiracji i możliwości każdego z uczniów. Indywidualnymi
          potrzebami będziemy zajmować się podczas zajęć z emisji głosu. Pozwolą
          one na porzucenie starych nawyków i nabranie nowych, zdrowych
          przyzwyczajeń, które umożliwią efektywne i wygodne korzystanie z
          możliwości wokalnych.Nauka śpiewu opiera się również na pracy z
          repertuarem. Kadra Music Spot zdobywała podstawy edukacji muzycznej w
          szkołach klasycznych, ale kształciła się też w szkołach i na
          akademiach muzycznych na wydziałach jazzu i muzyki rozrywkowej. Dobór
          repertuaru zatem w dużej mierze zależy od ucznia, jednak podczas zajęć
          wokalnych zachęcać będziemy również do poszerzania muzycznych
          horyzontów i do korzystania z doświadczenia i wiedzy nauczycieli.{' '}
        </p>
      </section>
      <section>
        <h3>Korzyści z lekcji śpiewu</h3>
        <p>
          Wiedza zdobyta podczas lekcji śpiewu i emisji głosu może być przydatna
          zarówno do celów indywidualnych, rozwijania pasji czy hobby,
          polepszenia samopoczucia lub jako forma kreatywnej odskoczni od
          rutyny. Jednak niezależnie od motywacji naszych klientów, w Music Spot
          wiedza przekazywana jest w sposób kompleksowy, umożliwiający
          zastosowanie nabytych umiejętności na poziomie profesjonalnym.
        </p>
      </section>
      <section>
        <h3>Przygotowanie do pracy scenicznej</h3>
        <p>
          Czerpiąc z bogatego doświadczenia naszej kadry, podczas zajęć
          wokalnych można bliżej zapoznać się z pracą sceniczną, pracą z
          mikrofonem, nagłośnieniem, a także mierzeniem się z tremą związaną z
          wystąpieniami publicznymi.
        </p>
      </section>
      <section>
        <h3>Uzupełnienie pracy wokalnej</h3>
        <p>
          Nauka śpiewu to rownież przygotowanie do pracy z profesjonalnymi
          muzykami, dlatego, w zależności od potrzeb, możliwe będzie wdrażanie
          elementów teorii i harmonii muzycznej, a także pracy nad formą i
          aranżacją utworów wybranych przez uczniów. Służymy bogatym
          doświadczeniem i wiedzą, dzięki której nasza szkoła śpiewu pozwala
          uczniom uczestniczyć we wspólnym muzykowaniu na równi z zawodowymi
          instrumentalistami.{' '}
        </p>
      </section>
      <section>
        <h3>Dbałość o ciągły rozwój</h3>
        <p>
          W trosce o rozwój i pogłębianie wiedzy, regularnie sami bierzemy
          udział w warsztatach muzycznych, głównie zagranicznych, a także
          szkoleniach dotyczących emisji głosu. Często sami pobieramy lekcje u
          wybranych przez nas mistrzów, dzięki którym rozwijamy najbardziej
          interesujące nas aspekty wiedzy muzycznej. Poprzez stałą chęć
          doskonalenia się, rozwijamy wachlarz umiejętności, którymi chętnie
          dzielimy się podczas lekcji śpiewu.
        </p>
      </section>
      <section style={{ marginTop: '70px', marginBottom: '50px' }}>
        <p>
          Serdecznie zapraszamy młodzież i dorosłych - mieszkańców miast:
          Jaworzno, Chrzanów, Libiąż, Olkusz, Katowice, Trzebinia, Imielin,
          Mysłowice, Sosnowiec, a także miejscowości położonych w dalszych
          rejonach
        </p>
      </section>
    </div>
  </>
)

export default NaukaSpiewuPage
